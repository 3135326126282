<template>
  <div class="card theme-card table-container scrollable-content">
    <alert v-if="files.length == 0" class="m-4"> No Files to Show </alert>
    <table v-else class="table table-striped mb-0 files-table">
      <tr>
        <th>Icon</th>
        <th><span class="desktop-only">Name</span></th>
        <th class="desktop-only">Size</th>
        <th class="desktop-only"></th>
      </tr>
      <tr v-for="file in files" :key="file.id">
        <td>
          <icon
            :type="
              getFileTypeByExtension(file)
                ? `file-${getFileTypeByExtension(file)}`
                : 'file'
            "
            class="fa-2x text-muted m-0"
          />
        </td>
        <td>
          {{ file.name }}
          <br />
          <span v-if="file.createdAt">
            <span class="text-right text-muted small">
              {{ prettyDate(file.createdAt) }}
            </span>
            <br />
          </span>
          <b class="mobile-tablet-only mr-2">Size:</b>
          <span class="small text-muted mobile-tablet-only">
            {{ file.size }}
          </span>
          <div class="mobile-tablet-only">
            <div class="mb-3 mt-2">
              <button
                @click="$emit('remove', file)"
                class="btn btn-sm btn-danger mt-2 ml-2"
              >
                <Icon type="xmark" />
              </button>
            </div>
          </div>
        </td>
        <td class="desktop-only">
          <span class="small text-muted">{{ file.size }}</span>
        </td>
        <td class="desktop-only text-center">
          <button
            v-tooltip="'Remove File'"
            @click="$emit('remove', file)"
            class="btn btn-sm btn-danger"
          >
            <Icon type="xmark" />
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import helpers from "@/utils/helpers";

export default {
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  emits: ["remove"],
  methods: {
    getFileTypeByExtension: (file) =>
      helpers.getFileTypeByExtension(file.extension),
    prettyDate: (date) => helpers.prettyDate(date),
  },
};
</script>
<style lang="scss" scoped>
.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}
</style>
