<template>
  <div>
    <div class="level text-center">
      <img
        v-if="avatarPath"
        :src="avatarPath"
        width="200"
        height="200"
        class="rounded-circle avatar-form-img"
      />
      <img
        v-else
        src="@/assets/img/avatars/profiles/avatar.jpg"
        width="200"
        height="200"
        class="m-auto rounded-circle avatar-form-img"
      />
    </div>

    <image-upload
      name="avatar"
      @loaded="onLoad"
      v-if="!viewOnly"
    ></image-upload>
  </div>
</template>

<script>
import ImageUpload from "./ImageUpload.vue";

export default {
  components: { ImageUpload },
  props: ["avatarPath", "viewOnly"],
  methods: {
    onLoad(avatar) {
      this.avatarPath = avatar.src;
      this.$emit("loaded", avatar);
    },
  },
};
</script>
